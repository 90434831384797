//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from 'vuex'
import DailyTheme from '~/components/Main/DailyTheme'
import api from '~/api/' // server
import StockIndex from '~/components/Main/StockIndex'
import NewsFlash from '~/components/Main/NewsFlash'
import StockCalendar from '~/components/Main/StockCalendar'
import Login from '~/components/Main/Login'
import Words from '~/components/Main/Words'
import TodaysWorld from '~/components/Main/TodaysWorld'
import IncreaseThemeRanking from '~/components/Main/IncreaseThemeRanking'
// import IncreaseThemeRankingMonth from '~/components/Main/IncreaseThemeRankingMonth'

export default {
    name: 'Main',
    components: {
        StockIndex,
        NewsFlash,
        StockCalendar,
        Login,
        TodaysWorld,
        IncreaseThemeRanking,
        DailyTheme,
        Words
        // IncreaseThemeRankingMonth,
    },
    computed: {
      ...mapState('member', ['memberInfo']),
    },
    created() {

    },
    mounted() {
      this.getHitsMain()
      // process.env.VUE_APP_REST_API
      console.log('process.env.VUE_APP_REST_API', process.env.NODE_ENV)
      // 22.05.16 suye 리뉴얼 관련 임시 팝업 
      // if (this.$cookies.get("popClose")!=='ok'){
        // window.open("https://new.infostock.co.kr/Renewal", "_blank", "width=500, height=450")
        // window.open("http://192.168.0.6/Renewal", "_blank", "width=500, height=450")
      // }
    },
    methods: {
      ...mapMutations('ui', ['SET_MOBILE_MENU_SHOW', 'SET_ISPOPUP_OPEN']),
      ...mapActions('modal', ['SHOW_ALERT']),
      // 23.03.16 SUYE 홈페이지 조회수 
      async getHitsMain() {
        const date = new Date();
        const year = date.getFullYear();
        const month = ("0" + (1 + date.getMonth())).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const today = year + month + day;
        const params = {
          showDate: today,
          pageName: 'infostock_main',
        }
        await api.getHitsMain(params).then((res) => {
          console.log('getHitsMain res', res)
        }).catch((err) => {
          console.log('getHitsMain err', err)
        })
      },
      handleClick() {
        if (this.memberInfo == null) {
            this.SHOW_ALERT({ content: '로그인 후 작성해주시길 바랍니다.' });
          }
        },
    },
}
