//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import api from '~/api/index'
import List from '~/components/Common/UI/List_2Col'
export default {
  name: 'DailyTheme',
  components: { List },
  data() {
      return {
          newsList: {
              items: [],
              nextKey: '',
          },
          newsType: 'MENU_DAILY_FEATURED_THEME',
      }
  },
  computed: {
      ...mapState({
          MEMBER_INFO: state => state.member.memberInfo,
      }),
      board() {
          return this.$store.state.board.newsList[this.newsType]
      },
  },
  created() {
      this.getList()
  },
  methods: {
      ...mapActions('modal', ['SHOW_ALERT']),
      async getList() {
          await api.getMainTickerList2().then((res)=>{
              if (res && res.success){
                  this.newsList = res.data
              } else {
                  alert('main_page newsflash err')
              }
          }).catch((err)=>{
              console.warn("main_page newsflash -> ", err)
              alert(err)
          })

      },
      onClickItem(item) {
        if(this.MEMBER_INFO === null 
              || (this.MEMBER_INFO !== null && this.MEMBER_INFO.type !== 'REGULAR' 
              && this.MEMBER_INFO.type !== 'CORPORATE')){
              const today = dayjs(new Date()).format('YYYYMMDD');
              if(today === item.sendDate){
                  this.SHOW_ALERT({ content: '유료회원님들을 위한 자료입니다.' })
                  return 
              }  
          }

          const id = item.id
          const newsType = item.newsType1
          // 유료회원 구분을 위해 sendDate 추가
          const sendDate = item.sendDate
          console.log("newsType : ", newsType)
          console.log("id : ", id)
          console.log("sendDate : ", sendDate)

          this.$router.push({
              path: '/Theme/DailyFeaturedTheme',
              query: { id, newsType, sendDate },
          })
      },
  },
}
