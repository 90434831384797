//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'Words',
  components: {
      [process.browser && 'wordcloud']: () => import('vue-wordcloud'),
    },
  data () {
    return {
      F_size:[15,35],
      rotate: { from: 0, to: 0 },
      // myColors: ['#173F60','#1B507C','#20619A', '#2779BD', '#4194D7', '#71B1E4'],
      myColors: ['#2F4354','#3B5469','#425E75', '#4C6C87', '#577C9C', '#638DB0', '#71A1C9', '#78ABD6', '#82BAE8', '#89C4F5'],
    }
  },
  computed: {
    ...mapGetters('main', ['word_toptheme']),

    defaultWords(){
      return this.word_toptheme.map(theme =>{
        return{
          name: `${theme.name} ` + `+${theme.rate}%`,
          value: parseInt(theme.rate) + "." + parseFloat(theme.code),
        }
      })
    }
  },
  mounted () {
    this.requestToptheme()
    this.wordClickHandler()
  },
  methods: {
    ...mapActions('main', ['WORD_GET_TOPTHEME']),

    isHoliday(year, month, date){
      const holiday = {
        2024:{
            1:[1],
            2:[9,12],
            3:[1],
            4:[10,29,30],
            5:[1,2,3,6,15],
            6:[6],
            7:[],
            8:[15],
            9:[16,17,18],
            10:[3, 9],
            11:[],
            12:[25, 31],
        },
      }
      // console.log((holiday[year][month]).includes(date))
      return (holiday[year][month]).includes(date);
    },

    requestToptheme() {
          const type = 'D'
          // let day = Date('20240504')
          let day = '';
          let params = {}
          const now = new Date();
          const year = now.getFullYear();
          const month = (now.getMonth() + 1).toString();
          const date = now.getDate().toString();
          let time = 9

          // 수능 순연
          if(year+month+date === "20241114")
            time +=1

          if (now.getHours() >= time){
            if (now.getDay() === 0 || now.getDay() === 6 || this.isHoliday(year, month, date)){
              day = '00000000'
            }
            else {
              day = dayjs(new Date()).format('YYYYMMDD');
            }
          } 
          else{
            day = '00000000'
          }

          params = {
              type,
              day,
          }
        this.WORD_GET_TOPTHEME(params)
    
    },

    wordClickHandler (name, value, vm) {
      const code = String(value).split('.')[1]
      console.log("code =" + code)
      // code = code + "0"*(3-code.length())
      this.$router.push(`/Theme/ThemeDB/${code}`)
    },
  },
}
